<template>
  <a-layout-sider collapsible v-model="collapsed" style="box-shadow: 2px 0 6px rgba(0,21,41,.35);">
    <div class="logo">
      {{$root.$data.AppName}}
    </div>
    <a-menu theme="dark" mode="inline"
      :selectedKeys="$root.$data.selectedKeys"
      :defaultSelectedKeys="['Dashboard']"
    >
      <a-menu-item key="Dashboard" @click="toPath({ route: 'Dashboard', key: 'Dashboard'})"><a-icon type="dashboard" /><span>控制台</span></a-menu-item>
      <template v-for="item in siderGroup">
        <template v-if="item.children && item.children.length > 0 && checkAuth(item)">
          <a-sub-menu @titleClick="subMenuClick" :key="item.subKey">
            <span slot="title"><a-icon :type="item.icon" /><span>{{item.name}}</span></span>
            <template v-for="citem in item.children">
              <a-menu-item v-if="checkAuth(citem)" :key="citem.key"  @click="toPath(citem)">
                <a-icon :type="citem.icon"/><span>{{citem.name}}</span>
              </a-menu-item>
            </template>
          </a-sub-menu>
        </template>
        <a-menu-item v-else-if="checkAuth(item)" :key="item.key" @click="toPath(item)">
          <a-icon :type="item.icon"/><span>{{item.name}}</span>
        </a-menu-item>
      </template>
    </a-menu>
  </a-layout-sider>
</template>

<script>
export default {
  data () {
    return {
      openKeys: [],
      selectedKeys: [],
      collapsed: false,
      siderGroup: [
        {
          authRole: [ 'SuperAdmin' ],
          authAction: [
            '/Role/GetAuths',
            '/Role/GetSelectList',
            '/Role/TableList',
            '/Role/Create',
            '/Role/Edit',
            '/Role/Delete',
            '/Account/TableList',
            '/Account/Create',
            '/Account/Edit',
            '/Account/Delete',
            '/Document/UserServiceAgreement',
            '/Document/PrivacyPolicy',
            '/Document/IntroductionAndFeatures',
            '/AppPaymentAccount/Create',
            '/AppPaymentAccount/TableList'
          ],
          name: '系统管理',
          icon: 'laptop',
          subKey: 'System',
          children: [
            {
              authRole: ["SuperAdmin"],
              authAction: [
                "/Organization/GetTree",
                "/Organization/Create",
                "/Organization/Edit",
                "/Organization/Delete",
                "/AutoSms/TableList",
              ],
              name: "组织架构",
              icon: "cluster",
              route: "SystemOrganization",
              key: "SystemOrganization",
            },
            {
              authRole: [ 'SuperAdmin' ],
              authAction: [
                '/Role/GetAuths',
                '/Role/GetSelectList',
                '/Role/TableList',
                '/Role/Create',
                '/Role/Edit',
                '/Role/Delete'
              ],
              name: '系统角色',
              icon: 'solution',
              route: 'SystemRole',
              key: 'SystemRole'
            },
            {
              authRole: [ 'SuperAdmin' ],
              authAction: [
                '/Account/TableList',
                '/Account/Create',
                '/Account/Edit',
                '/Account/Delete',
              ],
              name: '系统用户',
              icon: 'user',
              route: 'SystemUsers',
              key: 'SystemUsers',
            },
            {
              authRole: [ 'SuperAdmin' ],
              authAction: [
                '/Document/IntroductionAndFeatures',
              ],
              name: '系统简介和特色',
              icon: 'read',
              route: 'SystemIntroductionAndFeatures',
              key: 'SystemIntroductionAndFeatures',
            },
            {
              authRole: [ 'SuperAdmin' ],
              authAction: [
                '/Document/UserServiceAgreement',
              ],
              name: '用户服务协议',
              icon: 'book',
              route: 'SystemUserServiceAgreement',
              key: 'SystemUserServiceAgreement',
            },
            {
              authRole: [ 'SuperAdmin' ],
              authAction: [
                '/Document/PrivacyPolicy',
              ],
              name: '隐私政策',
              icon: 'file-protect',
              route: 'SystemPrivacyPolicy',
              key: 'SystemPrivacyPolicy',
            },
            {
              authRole: [ 'SuperAdmin' ],
              authAction: [],
              name: '渠道Api安全',
              icon: 'api',
              route: 'OpenApiSecurity',
              key: 'OpenApiSecurity',
            },
            {
              authRole: [ 'SuperAdmin' ],
              authAction: [
                "/AutoSms/TableList",
              ],
              name: '短信转发记录',
              icon: 'shake',
              route: 'AutoSms',
              key: 'AutoSms',
            },
            {
              authRole: [ 'SuperAdmin' ],
              authAction: [
                '/AppPaymentAccount/Create',
                '/AppPaymentAccount/TableList'
              ],
              name: '付款账户管理',
              icon: 'wallet',
              route: 'AppPaymentAccount',
              key: 'AppPaymentAccount',
            }
          ],
        },
        {
          authRole: [ 'Admin', 'SuperAdmin' ],
          partitionId: [ 'Main' ],
          authAction: [
            '/Customer/TableList',
            '/Customer/StaffCustomerList',
            '/ChargeScore/TableList',
            '/Customer/TableListByOfflineAS',
          ],
          name: '修理厂管理',
          icon: 'apartment',
          subKey: 'Customer',
          children: [
            {
              authRole: [ 'SuperAdmin', 'Admin' ],
              authAction: [
                '/Customer/TableList',
              ],
              name: '修理厂列表',
              icon: 'unordered-list',
              route: 'CustomerTableList',
              key: 'CustomerTableList',
            },
            {
              authRole: [ 'SuperAdmin', 'Admin' ],
              authAction: [
                '/ChargeScore/TableList',
              ],
              name: '修理厂挂帐额度',
              icon: 'wallet',
              route: 'CustomerTableListByChargeScore',
              key: 'CustomerTableListByChargeScore',
            },
            {
              authRole: [ 'SuperAdmin', 'Admin' ],
              authAction: [
                '/Customer/TableListByOfflineAS',
              ],
              name: '修理厂配件额度',
              icon: 'wallet',
              route: 'CustomerTableListByAS',
              key: 'CustomerTableListByAS',
            },
            {
              authRole: [ 'SuperAdmin' ],
              authAction: [],
              name: '修理厂线下配件',
              icon: 'wallet',
              route: 'CustomerTableListByOfflineAS',
              key: 'CustomerTableListByOfflineAS',
            },
            // {
            //   authRole: [ 'SuperAdmin', 'Admin' ],
            //   authAction: [
            //     '/Customer/StaffCustomerList',
            //   ],
            //   name: '客户经理展业统计',
            //   icon: 'bar-chart',
            //   route: 'CustomerStaffCustomerCount',
            //   key: 'CustomerStaffCustomerCount',
            // },
            // {
            //   authRole: [ 'SuperAdmin', 'Admin' ],
            //   authAction: [
            //     '/Customer/Wallet'
            //   ],
            //   name: '客户钱包',
            //   icon: 'wallet',
            //   route: 'CustomerWallet',
            //   key: 'CustomerWallet',
            // },
          ]
        },
        {
          authRole: [ 'SuperAdmin', 'Admin' ],
          authAction: [
            '/LossRate/TableList',
          ],
          name: '政策投放',
          icon: 'money-collect',
          subKey: 'Rate',
          children: [
            {
              authRole: [ 'SuperAdmin', 'Admin' ],
              authAction: [
                '/LossRate/TableList',
              ],
              name: '定损政策列表',
              icon: 'car',
              route: 'LossRateList',
              key: 'LossRateList',
            },
          ]
        },
        {
          authRole: [ 'Admin', 'SuperAdmin' ],
          partitionId: [ 'Main' ],
          authAction: [
            '/CarOrders/TableList',
            '/AssessDamageOrder/TableList',
            '/MQMaintain/NoAckTableList',
          ],
          name: '订单管理',
          icon: 'insurance',
          subKey: 'Order',
          children: [
            {
              authRole: [ 'SuperAdmin', 'Admin' ],
              authAction: [
              ],
              name: '维修保费比',
              icon: 'percentage',
              route: 'DamageVsPremium',
              key: 'DamageVsPremium',
            },
            {
              authRole: [ 'SuperAdmin', 'Admin' ],
              authAction: [
                '/CarOrders/TableList',
              ],
              name: '车险保单',
              icon: 'car',
              route: 'OrderCar',
              key: 'OrderCar',
            },
            {
              authRole: [ 'SuperAdmin', 'Admin' ],
              authAction: [
                '/AssessDamageOrder/TableList',
                '/AssessDamageOrder/Create',
                '/AssessDamageOrder/Edit',
              ],
              name: '定损单',
              icon: 'car',
              route: 'AssessDamageOrder',
              key: 'AssessDamageOrder',
            },
            {
              authRole: [ 'SuperAdmin', 'Admin' ],
              authAction: [
                '/MQMaintain/NoAckTableList',
                '/MQMaintain/GetNoAckLog',
              ],
              name: '推送错误日志',
              icon: 'reddit',
              route: 'MQNoAck',
              key: 'MQNoAck',
            },
          ]
        },
        {
          authRole: [ 'SuperAdmin', 'Admin' ],
          authAction: [
            '/CarPolicyInquiry/TableList',
          ],
          name: '车险询价订单',
          icon: 'profile',
          key: 'CarPolicyInquiry',
          route: 'CarPolicyInquiry',
        },
        {
          authRole: [ 'SuperAdmin', 'Admin' ],
          authAction: [
            '/BankFlow/ImportList',
            '/BankFlow/TableList',
          ],
          name: '回执单管理',
          icon: 'account-book',
          key: 'BankFlowTableList',
          route: 'BankFlowTableList',
        },
        {
          authRole: [ 'SuperAdmin', 'Admin' ],
          authAction: [
            '/InvoiceImage/TableList',
          ],
          name: '发票管理',
          icon: 'account-book',
          key: 'InvoiceImageTableList',
          route: 'InvoiceImageTableList',
        },
        {
          authRole: [ 'Admin', 'SuperAdmin' ],
          partitionId: [ 'Main' ],
          authAction: [
            '/BusinessShop/Create',
            '/BusinessShop/Edit',
            '/BusinessShop/UploadImages',
          ],
          name: '合作商家',
          icon: 'cluster',
          subKey: 'Business',
          children: [
            {
              authRole: [ 'SuperAdmin', 'Admin' ],
              authAction: [
                '/BusinessShop/Create',
                '/BusinessShop/Edit',
                '/BusinessShop/UploadImages',
              ],
              name: '修理厂展示',
              icon: 'unordered-list',
              route: 'BusinessShopTableList',
              key: 'BusinessShopTableList',
            },
          ]
        },
        {
          authRole: [ 'Admin', 'SuperAdmin' ],
          partitionId: [ 'Main' ],
          authAction: [
            '/ASUsers/TableList',
            '/AccessoriesOrders/TableList',
            '/AccessoriesOrders/TableListByScore'
          ],
          name: '配件供应商',
          icon: 'cluster',
          subKey: 'Accessories',
          children: [
            {
              authRole: [ 'SuperAdmin', 'Admin' ],
              authAction: [
                '/ASUsers/TableList',
              ],
              name: '供应商列表',
              icon: 'unordered-list',
              route: 'AccessoriesTable',
              key: 'AccessoriesTable',
            },
            {
              authRole: [ 'SuperAdmin', 'Admin' ],
              authAction: [
                '/AccessoriesOrders/TableListByScore'
              ],
              name: '配件额度订单',
              icon: 'unordered-list',
              route: 'AccessoriesOrderScore',
              key: 'AccessoriesOrderScore',
            },
            {
              authRole: [ 'SuperAdmin', 'Admin' ],
              authAction: [
                '/AccessoriesOrders/TableList'
              ],
              name: '配件订单',
              icon: 'unordered-list',
              route: 'AccessoriesOrderTable',
              key: 'AccessoriesOrderTable',
            },
          ]
        },
        // {
        //   authRole: [ 'Admin', 'SuperAdmin' ],
        //   partitionId: [ 'Main' ],
        //   authAction: [
        //     '/Supplier/Create',
        //     '/Supplier/Edit',
        //     '/Supplier/UploadImages',
        //   ],
        //   name: '供应商',
        //   icon: 'global',
        //   subKey: 'Supplier',
        //   children: [
        //     {
        //       authRole: [ 'SuperAdmin', 'Admin' ],
        //       authAction: [
        //         '/Supplier/Create',
        //         '/Supplier/Edit',
        //         '/Supplier/UploadImages',
        //       ],
        //       name: '供应商管理',
        //       icon: 'unordered-list',
        //       route: 'SupplierTableList',
        //       key: 'SupplierTableList',
        //     },
        //   ]
        // }
      ]
    }
  },
  methods: {
    checkAuth (item) {
      const partitionId = this.$root.$data.privateState.partitionId
      if (item.partitionId && !item.partitionId.includes(partitionId)) {
        return false;
      }
      if (item.notPartitionId && item.notPartitionId.includes(partitionId)) {
        return false;
      }
      if (item.authRole.includes(this.$root.$data.privateState.role)) {
        return true
      }
      if (item.authAction === undefined) return true
      for (const it of item.authAction) {
        if (this.$root.$data.privateState.action.includes(it)) {
          return true
        }
      }
      return false
    },
    subMenuClick ({ key }) {
      if (this.$root.$data.openKeys.includes(key)) {
        this.$root.$data.openKeys.splice(this.$root.$data.openKeys.indexOf(key), 1)
      } else {
        this.$root.$data.openKeys.push(key)
      }
    },
    toPath (item) {
      this.$root.$data.selectedKeys = [item.key]
      this.$router.push({ name: item.route }).catch(err => err)
    },
    changeCollapsed () {
      this.collapsed = !this.collapsed
      return this.collapsed
    }
  },
}
</script>
<style>
.logo {
  color: #fff;
  text-align: center;
  font-size: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
}
</style>
